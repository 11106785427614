Kubrick.LogoGrid = {
  // Delay between animations
  DELAY: 500,

  // Animation
  DURATION: 1000,

  // Number of visible logos shown
  VISIBLE_LOGOS: 12,

  // Selectors
  _selector: "#content .block.logogrid",
  _selector_logo: ".logo",

  //
  _controllers: [],
  _configs: [],

  start: function(config) {
    Kubrick._log("LogoGrid: Configuring...", config);
    this._configs.push(config);
    this.setup(config, config.id);
  },

  setup: function(config, id) {
    let $BLOCK = document.querySelector(this._selector + "." + id);
    if ($BLOCK) {
      var controller = {
        config: config,
        $BLOCK: $BLOCK,
        $LOGOS: $BLOCK.querySelectorAll(this._selector_logo)
      };
      this._setTimer(controller);
      this._controllers.push(controller);
    }
  },

  _setTimer: function(controller) {
    controller.timeoutID = setTimeout(
      this._animateGrid.bind(this),
      this.DELAY,
      controller
    );
  },

  _getUniqueLogo: function(controller) {
    let rand_logo = Math.floor(
      Math.random() * Math.floor(controller.config.data.length)
    );
    let logo = controller.config.data[rand_logo];
    let test_url = decodeURIComponent(logo.url)
      .split("/")
      .pop();
    let existing = controller.$BLOCK.querySelectorAll(
      "img[src*='" + test_url + "']"
    );
    if (existing.length > 0) {
      // Kubrick._log("LogoGrid: Existing logo, getting another...", test_url);
      return this._getUniqueLogo(controller);
    }
    // Kubrick._log("LogoGrid: New logo found...", logo);
    return logo;
  },

  _animateGrid: function(controller) {
    // Scope
    var self = this;

    // Get a loog
    let rand_logo = this._getUniqueLogo(controller);

    // Select a slot
    let range = controller.config.limit || this.VISIBLE_LOGOS;
    let rand_slot = Math.floor(Math.random() * Math.floor(range));

    // Create new image
    var new_logo = new Image();
    new_logo.classList.add("image");
    var slot = controller.$LOGOS[rand_slot];
    var old_logo = slot.querySelector("img");

    // Wait for image to be loaded
    new_logo.addEventListener(
      "load",
      function() {
        anime({
          targets: old_logo,
          opacity: [1, 0],
          duration: self.DURATION,
          easing: Kubrick.EASING.NORMAL,
          complete: function() {
            // Remove old logo
            old_logo.remove();

            // Set new target
            let targ = slot.appendChild(new_logo);

            // Animate
            anime({
              targets: targ,
              opacity: [0, 1],
              duration: self.DURATION,
              easing: Kubrick.EASING.NORMAL
            });

            self._setTimer(controller);
          }
        });
      },
      false
    );
    new_logo.src = decodeURIComponent(rand_logo.url);
  }
};
